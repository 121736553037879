import * as React from 'react';
import { graphql } from 'gatsby';
import SiteWrapper from '../components/siteWrapper';
import Page from '../components/page/pageNew';
import Nav from '../components/menu/vereinNav';
import styled from 'styled-components';
import { FilePdf } from 'styled-icons/boxicons-solid/';

const SatzungWrapper = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Satzung = styled.a`
    color: ${(props) => props.theme.colors.darkGray};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const IndexPage = (props) => {
    const node = props.data.allFile.edges[0].node.childMarkdownRemark;

    return (
        <SiteWrapper
            title="Sportverein Kappel 1953 e.V."
            description="Hier erfährst du mehr über den Freiburger Verein aus dem Dreisamtal. 1953 gegründet und mittlerweile mit 4 Abteilungen und unzähligen Angeboten nicht mehr wegzudenken..."
        >
            <Nav />
            <Page
                title={node.frontmatter.title}
                headerImg={node.frontmatter.headerImg}
                html={node.html}
            >
                {node?.frontmatter?.satzung?.publicURL && (
                    <SatzungWrapper>
                        <Satzung href={`${node.frontmatter.satzung.publicURL}`}>
                            <FilePdf size={70} />
                            <span>Satzung</span>
                        </Satzung>
                    </SatzungWrapper>
                )}
            </Page>
        </SiteWrapper>
    );
};

export default IndexPage;

export const query = graphql`
    query SatzungQuery {
        allFile(
            filter: {
                sourceInstanceName: { eq: "sites" }
                name: { eq: "satzung" }
            }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        html
                        frontmatter {
                            title
                            satzung {
                                publicURL
                            }
                            headerImg {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
