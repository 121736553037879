import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { getSrc } from 'gatsby-plugin-image';
import Content from '../content';
import { BigText } from '../common';
import Plx from 'react-plx';

const Wrapper = styled.div``;
const Header = styled.div`
    width: 100%;
`;
const HeaderImg = styled.img`
    width: 100%;
    height: auto;
`;
const TitleWrap = styled.div`
    padding: 20px;
`;
const ContentWrapper = styled.div`
    background: #fff;
`;
const BeforeContent = styled.div`
    padding: 20px;
`;

const contentPlx = [
    {
        start: 0,
        end: 300,
        properties: [
            {
                startValue: 0,
                endValue: -100,
                property: 'translateY',
            },
        ],
    },
];

const Index = ({ headerImg, children, title, html }) => {
    const [height, setHeight] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current.clientHeight) {
            setTimeout(() => {
                setHeight(ref.current.clientHeight);
            }, 1000);
        }
    });

    const headerPlx = [
        {
            start: 0,
            end: height ? height - 50 : 200,
            properties: [
                {
                    startValue: 1,
                    endValue: 0,
                    property: 'opacityFilter',
                },
            ],
        },
    ];

    return (
        <Wrapper>
            <Plx parallaxData={headerPlx}>
                <Header ref={ref}>
                    <HeaderImg src={getSrc(headerImg)} alt="Headerbild" />
                </Header>
            </Plx>
            <Plx parallaxData={contentPlx}>
                <ContentWrapper>
                    {title && (
                        <TitleWrap>
                            <BigText>{title}</BigText>
                        </TitleWrap>
                    )}
                    {children && <BeforeContent>{children}</BeforeContent>}
                    <Content>{html}</Content>
                </ContentWrapper>
            </Plx>
        </Wrapper>
    );
};

export default Index;
